import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 24 31">
    <path
      fill={color}
      d="M1.2,9.3l1.5,10.8c0.1,0.7,0.4,1.4,0.9,1.9c-0.6,0.9-1,2.1-1,3.2v4.5c0,0.7,0.5,1.3,1.2,1.3h3.3h9.5H20
	c0.7,0,1.2-0.6,1.2-1.3v-4.5c0-1.2-0.4-2.3-1-3.3c0.5-0.5,0.8-1.1,0.9-1.9l1.5-10.7C23.4,9.2,24,8.5,24,7.6c0-1-0.7-1.7-1.7-1.7
	h-2.9C18,3.1,14.7,0,12,0C9.3,0,6,3.1,4.6,6H1.7C0.7,6,0,6.7,0,7.7C0,8.5,0.5,9.1,1.2,9.3z M2.2,9.4h3.9l0.1,0.9
	c-0.6,0.4-0.9,1.1-0.9,1.9c0,1,0.6,1.9,1.4,2.1c0.3,1.6,1.2,3,2.4,3.8l-0.1,0.8c-0.1,0-0.2,0-0.2,0l-0.4,0.2c-0.6,0.3-1,0.4-1.5,0.5
	c-1,0.2-1.9,0.7-2.7,1.4c-0.3-0.3-0.5-0.8-0.6-1.2L2.2,9.4z M16.8,9.4c0,0.3-0.1,0.7-0.1,1c0,0.2,0.1,0.5,0.3,0.6
	c0.4,0.1,0.6,0.6,0.6,1.1c0,0.6-0.4,1.1-0.8,1.1c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0s-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1
	c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0C15.9,16.2,14,18,11.9,18c-2.1,0-3.9-1.9-4.3-4.3c0,0,0,0,0,0c0,0,0,0,0,0
	c0-0.1,0-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0
	c-0.4,0-0.8-0.5-0.8-1.1c0-0.5,0.3-1,0.6-1.1c0.2-0.1,0.4-0.3,0.3-0.6L7.1,9.4L16.8,9.4C16.8,9.4,16.8,9.4,16.8,9.4z M14.8,24.5H9.2
	v-1.6h5.7V24.5z M9.2,21.9v-1.8c0.5,0.2,1.7,0.7,2.8,0.7c1.2,0,2.3-0.4,2.8-0.7v1.8H9.2z M8.2,21.9H5.7c-0.2,0-0.4,0-0.6-0.1
	c0.5-0.5,1.2-0.9,1.9-1c0.3-0.1,0.7-0.2,1.1-0.3V21.9z M15.8,20.5c0.4,0.1,0.7,0.2,1.1,0.3c0.7,0.1,1.4,0.5,1.9,1
	c-0.2,0.1-0.4,0.1-0.6,0.1h-2.3C15.8,21.9,15.8,20.5,15.8,20.5z M13.9,19.4c-0.5,0.2-1.2,0.4-1.9,0.4s-1.4-0.2-1.9-0.4l0.1-0.7
	c0.6,0.3,1.2,0.4,1.9,0.4s1.3-0.1,1.9-0.4V19.4z M3.7,29.7v-4.5c0-1,0.3-1.9,0.8-2.6c0.4,0.2,0.8,0.3,1.2,0.3h2.4v1.6
	c-0.8,0.2-1.4,1-1.4,1.9V30H6.3v-1.8c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5V30H3.9C3.8,30,3.7,29.9,3.7,29.7z M7.7,30v-3.5
	c0-0.5,0.4-1,0.9-1h6.7c0.5,0,0.9,0.4,0.9,1V30H7.7z M20.3,25.2v4.5c0,0.1-0.1,0.2-0.2,0.2h-1.4v-1.8c0-0.3-0.2-0.5-0.5-0.5
	c-0.3,0-0.5,0.2-0.5,0.5V30h-0.4v-3.5c0-0.9-0.6-1.7-1.4-1.9v-1.6h2.3c0.5,0,0.9-0.1,1.3-0.3C20,23.3,20.3,24.2,20.3,25.2z
	 M20.2,19.9c-0.1,0.4-0.3,0.9-0.5,1.2c-0.7-0.7-1.6-1.2-2.6-1.4c-0.5-0.1-0.9-0.2-1.5-0.5L15.1,19c-0.1,0-0.2-0.1-0.2,0l-0.1-0.8
	c1.2-0.9,2-2.2,2.4-3.9c0.8-0.2,1.4-1.1,1.4-2.1c0-0.8-0.4-1.6-0.9-1.9c0-0.2,0.1-0.4,0.1-0.7c0,0,0-0.1,0-0.2h3.8L20.2,19.9z M12,1
	c1.9,0,4.8,2.3,6.3,4.9H5.7C7.2,3.3,10.1,1.1,12,1z M1.7,7h3.2c0,0,0,0,0,0h14.2h3.2C22.7,7,23,7.3,23,7.7c0,0.4-0.3,0.7-0.7,0.7
	h-0.1h-4.8c0,0,0,0-0.1,0c0,0,0,0-0.1,0H1.7C1.3,8.4,1,8.1,1,7.7C1,7.3,1.3,7,1.7,7z"
    />
  </svg>
);
